<template>
  <b-row>
    <b-col cols="12">
      <b-card :title="$t('Expirations report filters') ">
        <div class="m-2">

          <b-row>

            <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >

              <b-form-group
                  :label="$t('Expiration till')"
                  label-for="end"
              >
                <flat-pickr
                    id="end"
                    class="form-control"
                    v-model="end"
                    variant="outline-secondary"

                    :config="flatPickrConfig"
                />
              </b-form-group>

            </b-col>

            <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <b-form-group
                  :label="$t('Filter by days to the expiration')"
                  label-for="end"
              >
                <b-dropdown
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :text="filters[0].description"
                    variant="outline-secondary"
                    block
                >
                  <b-dropdown-item
                      v-for="filter in filters"
                      :key="filter.id"
                      @click="setFilter(filter.id)"
                  >
                    <span class="ml-50">{{ filter.description }}</span>
                  </b-dropdown-item>

                </b-dropdown>
              </b-form-group>

            </b-col>
            <b-col
                cols="12"
                md="4"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
            </b-col>
            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >

              <b-button
                  variant="primary"
                  class="button-inline-spacing-2"
                  @click="default_filter()"
                  style="min-width: 135px"

              >
                <span class="text-nowrap">{{ $t("Remove filter") }}</span>
              </b-button>

            </b-col>
          </b-row>

          <b-row>

            <b-col
                cols="12"
                md="10"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >


            </b-col>

            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                v-if="allow_export"

            >


              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="download_report()"
                  class="button-inline-spacing-2"
                  style="min-width: 135px"
              >
                <feather-icon
                    icon="PrinterIcon"
                    class="mr-50"
                />
                <span class="align-middle">{{ $t("Export") }}</span>
              </b-button>

            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-card-actions ref="courses_list" :title="$t('Expiring courses reports')"  actionCollapse>
        <!-- table -->
        <vue-good-table
            :columns="courses_columns"
            :rows="courses"
            :rtl="direction"
            :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
            :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
            :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
        >

          <!-- Slot: Table Column -->
          <template
              slot="table-column"
              slot-scope="props"
          >

        <span>
           {{ $t(props.column.label) }}
        </span>
          </template>

          <!-- Slot: Table Row -->
          <template
              slot="table-row"
              slot-scope="props"
          >


            <!-- Column: Name -->
            <span
                v-if="props.column.field === 'name'"
                class="text-nowrap v-align-middle"
            >
          <span>{{ props.row.name }}</span>
        </span>


            <!-- Column: title -->
            <span
                v-if="props.column.field === 'title'"
                class="text-nowrap v-align-middle"
            >
          <span>{{ props.row.title }}</span>
        </span>


            <!-- Column: date_event -->
            <span
                v-if="props.column.field === 'date_event'"
                class="text-nowrap v-align-middle date"
            >
          <span>{{ props.row.date_event }}</span>
        </span>


            <!-- Column: expiration -->
            <span
                v-if="props.column.field === 'expiration'"
                class="text-nowrap v-align-middle date"
            >
              <b-badge
                  pill
                  :variant="getExpirationVariant(props.row)"
                  class="text-capitalize"
              >
            {{ props.row.expiration }}
          </b-badge>

        </span>


            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'" class="button-inline-spacing">

          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="edit(props.row)"

          >
      <feather-icon icon="Edit2Icon"/>
    </b-button>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="deleteDepartment(props.row)"
            >
      <feather-icon icon="TrashIcon"/>
    </b-button>


        </span>

            <span v-else class="text-nowrap align-middle">


        </span>
          </template>

          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >

            <div class="d-flex justify-content-between flex-wrap">


              <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('message.pagelength') }}
            </span>
                <b-form-select
                    v-model="pageLength"
                    :options="['25','50','100']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{
                    $t('message.pageText2')
                  }} </span>
              </div>
              <div>

                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>

                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>


      </b-card-actions>
      <b-card-actions ref="medical_list" :title="$t('Expiring visits reports')" actionCollapse>

        <!-- table -->
        <vue-good-table
            :columns="medical_columns"
            :rows="medical"
            :rtl="direction"
            :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
            :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
            :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
        >

          <!-- Slot: Table Column -->
          <template
              slot="table-column"
              slot-scope="props"
          >

        <span>
           {{ $t(props.column.label) }}
        </span>
          </template>

          <!-- Slot: Table Row -->
          <template
              slot="table-row"
              slot-scope="props"
          >


            <!-- Column: Name -->
            <span
                v-if="props.column.field === 'name'"
                class="text-nowrap v-align-middle"
            >
          <span>{{ props.row.name }}</span>
        </span>


            <!-- Column: title -->
            <span
                v-if="props.column.field === 'title'"
                class="text-nowrap v-align-middle"
            >
          <span>{{ props.row.title }}</span>
        </span>


            <!-- Column: date_event -->
            <span
                v-if="props.column.field === 'date_event'"
                class="text-nowrap v-align-middle date"
            >
          <span>{{ props.row.date_event }}</span>
        </span>


            <!-- Column: expiration -->
            <span
                v-if="props.column.field === 'expiration'"
                class="text-nowrap v-align-middle date"
            >
              <b-badge
                  pill
                  :variant="getExpirationVariant(props.row)"
                  class="text-capitalize"
              >
            {{ props.row.expiration }}
          </b-badge>

        </span>


            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'" class="button-inline-spacing">

          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="edit(props.row)"

          >
      <feather-icon icon="Edit2Icon"/>
    </b-button>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="deleteDepartment(props.row)"
            >
      <feather-icon icon="TrashIcon"/>
    </b-button>


        </span>

            <span v-else class="text-nowrap align-middle">


        </span>
          </template>

          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >

            <div class="d-flex justify-content-between flex-wrap">


              <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('message.pagelength') }}
            </span>
                <b-form-select
                    v-model="pageLength"
                    :options="['25','50','100']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{
                    $t('message.pageText2')
                  }} </span>
              </div>
              <div>

                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>

                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>


      </b-card-actions>
      <b-card-actions ref="documents_list" :title="$t('Expiring documents reports')" actionCollapse>
        <!-- table -->
        <vue-good-table
            :columns="employees_document_columns"
            :rows="employee_documents"
            :rtl="direction"
            :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
            :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
            :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
        >

          <!-- Slot: Table Column -->
          <template
              slot="table-column"
              slot-scope="props"
          >

        <span>
           {{ $t(props.column.label) }}
        </span>
          </template>

          <!-- Slot: Table Row -->
          <template
              slot="table-row"
              slot-scope="props"
          >


            <!-- Column: Name -->
            <span
                v-if="props.column.field === 'name'"
                class="text-nowrap v-align-middle"
            >
          <span>{{ props.row.name }}</span>
        </span>


            <!-- Column: title -->
            <span
                v-if="props.column.field === 'title'"
                class="text-nowrap v-align-middle"
            >
          <span>{{ props.row.title }}</span>
        </span>


            <!-- Column: date_event -->
            <span
                v-if="props.column.field === 'date_event'"
                class="text-nowrap v-align-middle date"
            >
          <span>{{ props.row.date_event }}</span>
        </span>


            <!-- Column: expiration -->
            <span
                v-if="props.column.field === 'expiration'"
                class="text-nowrap v-align-middle date"
            >
              <b-badge
                  pill
                  :variant="getExpirationVariant(props.row)"
                  class="text-capitalize"
              >
            {{ props.row.expiration }}
          </b-badge>

        </span>


            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'" class="button-inline-spacing">

          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="edit(props.row)"

          >
      <feather-icon icon="Edit2Icon"/>
    </b-button>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="deleteDepartment(props.row)"
            >
      <feather-icon icon="TrashIcon"/>
    </b-button>


        </span>

            <span v-else class="text-nowrap align-middle">


        </span>
          </template>

          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >

            <div class="d-flex justify-content-between flex-wrap">


              <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('message.pagelength') }}
            </span>
                <b-form-select
                    v-model="pageLength"
                    :options="['25','50','100']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{
                    $t('message.pageText2')
                  }} </span>
              </div>
              <div>

                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>

                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>


      </b-card-actions>
      <b-card-actions ref="vehicles_list" :title="$t('Expiring vehicles reports')" actionCollapse>

        <!-- table -->
        <vue-good-table
            :columns="vehicles_columns"
            :rows="vehicles"
            :rtl="direction"
            :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
            :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
            :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
        >

          <!-- Slot: Table Column -->
          <template
              slot="table-column"
              slot-scope="props"
          >

        <span>
           {{ $t(props.column.label) }}
        </span>
          </template>

          <!-- Slot: Table Row -->
          <template
              slot="table-row"
              slot-scope="props"
          >


            <!-- Column: Name -->
            <span
                v-if="props.column.field === 'name'"
                class="text-nowrap v-align-middle"
            >
          <span>{{ props.row.name }}</span>
        </span>


            <!-- Column: title -->
            <span
                v-if="props.column.field === 'title'"
                class="text-nowrap v-align-middle"
            >
          <span>{{ props.row.title }}</span>
        </span>


            <!-- Column: date_event -->
            <span
                v-if="props.column.field === 'date_event'"
                class="text-nowrap v-align-middle date"
            >
          <span>{{ props.row.date_event }}</span>
        </span>


            <!-- Column: expiration -->
            <span
                v-if="props.column.field === 'expiration'"
                class="text-nowrap v-align-middle date"
            >
              <b-badge
                  pill
                  :variant="getExpirationVariant(props.row)"
                  class="text-capitalize"
              >
            {{ props.row.expiration }}
          </b-badge>

        </span>


            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'" class="button-inline-spacing">

          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="edit(props.row)"

          >
      <feather-icon icon="Edit2Icon"/>
    </b-button>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="deleteDepartment(props.row)"
            >
      <feather-icon icon="TrashIcon"/>
    </b-button>


        </span>

            <span v-else class="text-nowrap align-middle">


        </span>
          </template>

          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >

            <div class="d-flex justify-content-between flex-wrap">


              <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('message.pagelength') }}
            </span>
                <b-form-select
                    v-model="pageLength"
                    :options="['25','50','100']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{
                    $t('message.pageText2')
                  }} </span>
              </div>
              <div>

                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>

                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>


      </b-card-actions>
      <b-card-actions ref="equipments_list" :title="$t('Expiring equipments reports')" actionCollapse>

        <!-- table -->
        <vue-good-table
            :columns="equipments_columns"
            :rows="equipments"
            :rtl="direction"
            :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
            :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
            :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
        >

          <!-- Slot: Table Column -->
          <template
              slot="table-column"
              slot-scope="props"
          >

        <span>
           {{ $t(props.column.label) }}
        </span>
          </template>

          <!-- Slot: Table Row -->
          <template
              slot="table-row"
              slot-scope="props"
          >


            <!-- Column: Name -->
            <span
                v-if="props.column.field === 'name'"
                class="text-nowrap v-align-middle"
            >
          <span>{{ props.row.name }}</span>
        </span>


            <!-- Column: title -->
            <span
                v-if="props.column.field === 'title'"
                class="text-nowrap v-align-middle"
            >
          <span>{{ props.row.title }}</span>
        </span>


            <!-- Column: date_event -->
            <span
                v-if="props.column.field === 'date_event'"
                class="text-nowrap v-align-middle date"
            >
          <span>{{ props.row.date_event }}</span>
        </span>


            <!-- Column: expiration -->
            <span
                v-if="props.column.field === 'expiration'"
                class="text-nowrap v-align-middle date"
            >
              <b-badge
                  pill
                  :variant="getExpirationVariant(props.row)"
                  class="text-capitalize"
              >
            {{ props.row.expiration }}
          </b-badge>

        </span>


            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'" class="button-inline-spacing">

          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="edit(props.row)"

          >
      <feather-icon icon="Edit2Icon"/>
    </b-button>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="deleteDepartment(props.row)"
            >
      <feather-icon icon="TrashIcon"/>
    </b-button>


        </span>

            <span v-else class="text-nowrap align-middle">


        </span>
          </template>

          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >

            <div class="d-flex justify-content-between flex-wrap">


              <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('message.pagelength') }}
            </span>
                <b-form-select
                    v-model="pageLength"
                    :options="['25','50','100']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{
                    $t('message.pageText2')
                  }} </span>
              </div>
              <div>

                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>

                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>


      </b-card-actions>
      <b-card-actions ref="plants_list" :title="$t('Expiring plants reports')" actionCollapse>

        <!-- table -->
        <vue-good-table
            :columns="plants_columns"
            :rows="plants"
            :rtl="direction"
            :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
            :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
            :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
        >

          <!-- Slot: Table Column -->
          <template
              slot="table-column"
              slot-scope="props"
          >

        <span>
           {{ $t(props.column.label) }}
        </span>
          </template>

          <!-- Slot: Table Row -->
          <template
              slot="table-row"
              slot-scope="props"
          >


            <!-- Column: Name -->
            <span
                v-if="props.column.field === 'name'"
                class="text-nowrap v-align-middle"
            >
          <span>{{ props.row.name }}</span>
        </span>


            <!-- Column: title -->
            <span
                v-if="props.column.field === 'title'"
                class="text-nowrap v-align-middle"
            >
          <span>{{ props.row.title }}</span>
        </span>


            <!-- Column: date_event -->
            <span
                v-if="props.column.field === 'date_event'"
                class="text-nowrap v-align-middle date"
            >
          <span>{{ props.row.date_event }}</span>
        </span>


            <!-- Column: expiration -->
            <span
                v-if="props.column.field === 'expiration'"
                class="text-nowrap v-align-middle date"
            >
              <b-badge
                  pill
                  :variant="getExpirationVariant(props.row)"
                  class="text-capitalize"
              >
            {{ props.row.expiration }}
          </b-badge>

        </span>


            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'" class="button-inline-spacing">

          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="edit(props.row)"

          >
      <feather-icon icon="Edit2Icon"/>
    </b-button>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="deleteDepartment(props.row)"
            >
      <feather-icon icon="TrashIcon"/>
    </b-button>


        </span>

            <span v-else class="text-nowrap align-middle">


        </span>
          </template>

          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >

            <div class="d-flex justify-content-between flex-wrap">


              <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('message.pagelength') }}
            </span>
                <b-form-select
                    v-model="pageLength"
                    :options="['25','50','100']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{
                    $t('message.pageText2')
                  }} </span>
              </div>
              <div>

                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>

                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>


      </b-card-actions>
      <b-card-actions ref="fulfillments_list" :title="$t('Expiring fulfillments reports')" actionCollapse>

        <!-- table -->
        <vue-good-table
            :columns="fulfillments_columns"
            :rows="fulfillments"
            :rtl="direction"
            :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
            :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
            :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
        >

          <!-- Slot: Table Column -->
          <template
              slot="table-column"
              slot-scope="props"
          >

        <span>
           {{ $t(props.column.label) }}
        </span>
          </template>

          <!-- Slot: Table Row -->
          <template
              slot="table-row"
              slot-scope="props"
          >


            <!-- Column: Name -->
            <span
                v-if="props.column.field === 'name'"
                class="text-nowrap v-align-middle"
            >
          <span>{{ props.row.name }}</span>
        </span>


            <!-- Column: title -->
            <span
                v-if="props.column.field === 'title'"
                class="text-nowrap v-align-middle"
            >
          <span>{{ props.row.title }}</span>
        </span>


            <!-- Column: date_event -->
            <span
                v-if="props.column.field === 'date_event'"
                class="text-nowrap v-align-middle date"
            >
          <span>{{ props.row.date_event }}</span>
        </span>


            <!-- Column: expiration -->
            <span
                v-if="props.column.field === 'expiration'"
                class="text-nowrap v-align-middle date"
            >
              <b-badge
                  pill
                  :variant="getExpirationVariant(props.row)"
                  class="text-capitalize"
              >
            {{ props.row.expiration }}
          </b-badge>

        </span>


            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'" class="button-inline-spacing">

          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="edit(props.row)"

          >
      <feather-icon icon="Edit2Icon"/>
    </b-button>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="deleteDepartment(props.row)"
            >
      <feather-icon icon="TrashIcon"/>
    </b-button>


        </span>

            <span v-else class="text-nowrap align-middle">


        </span>
          </template>

          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >

            <div class="d-flex justify-content-between flex-wrap">


              <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('message.pagelength') }}
            </span>
                <b-form-select
                    v-model="pageLength"
                    :options="['25','50','100']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{
                    $t('message.pageText2')
                  }} </span>
              </div>
              <div>

                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>

                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>


      </b-card-actions>
      <b-card-actions ref="others_list" :title="$t('Other expirations')" actionCollapse>

        <!-- table -->
        <vue-good-table
            :columns="others_columns"
            :rows="others"
            :rtl="direction"
            :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
            :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
            :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
        >

          <!-- Slot: Table Column -->
          <template
              slot="table-column"
              slot-scope="props"
          >

        <span>
           {{ $t(props.column.label) }}
        </span>
          </template>

          <!-- Slot: Table Row -->
          <template
              slot="table-row"
              slot-scope="props"
          >


            <!-- Column: Name -->
            <span
                v-if="props.column.field === 'name'"
                class="text-nowrap v-align-middle"
            >
          <span>{{ props.row.name }}</span>
        </span>




            <!-- Column: expiration -->
            <span
                v-if="props.column.field === 'expiration'"
                class="text-nowrap v-align-middle date"
            >
              <b-badge
                  pill
                  :variant="getExpirationVariant(props.row)"
                  class="text-capitalize"
              >
            {{ props.row.expiration  }}
          </b-badge>

        </span>


            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'" class="button-inline-spacing">

          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="edit(props.row)"

          >
      <feather-icon icon="Edit2Icon"/>
    </b-button>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="deleteDepartment(props.row)"
            >
      <feather-icon icon="TrashIcon"/>
    </b-button>


        </span>

            <span v-else class="text-nowrap align-middle">


        </span>
          </template>

          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >

            <div class="d-flex justify-content-between flex-wrap">


              <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('message.pagelength') }}
            </span>
                <b-form-select
                    v-model="pageLength"
                    :options="['25','50','100']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{
                    $t('message.pageText2')
                  }} </span>
              </div>
              <div>

                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>

                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>


      </b-card-actions>


    </b-col>
  </b-row>
</template>


<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {BCardActions} from "@core/components/b-card-actions";
import moment from 'moment';
import flatPickr from 'vue-flatpickr-component'

import {
  BAvatar,
  BBadge,
  BPagination,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BAlert,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BCard,

} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import {codeI18n} from './code'
import useJwt from '@/auth/jwt/useJwt'
import UserService from "@/basic/userService";
import EquipmentService from "@/basic/equipmentService";
import Ripple from 'vue-ripple-directive'

import {BButton} from "bootstrap-vue";
import ExportExcel from "@/views/components/excel-export/ExportExcel";

import Vue from "vue";
import i18n from "@/libs/i18n";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ability from "@/libs/acl/ability";
import DashboardService from "@/basic/dashboardService";
import {environment} from "@/auth/environment";

Vue.use(ExportExcel);

export default {
  components: {
    BCardCode,
    BCardActions,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BForm,
    BCard,
    flatPickr,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 25,
      codeI18n,


      courses_columns: [
        {
          label: 'Employee',
          field: 'name',
        },
        {
          label: 'Type of course',
          field: 'title',
        },
        {
          label: 'Course date',
          field: 'date_event',
        },
        {
          label: 'Next expiration',
          field: 'expiration',
        },

      ],
      medical_columns: [
        {
          label: 'Employee',
          field: 'name',
        },
        {
          label: 'Type of visit',
          field: 'title',
        },
        {
          label: 'Visit date',
          field: 'date_event',
        },
        {
          label: 'Next expiration',
          field: 'expiration',
        },

      ],
      employees_document_columns: [
        {
          label: 'Employee',
          field: 'name',
        },
        {
          label: 'Type of document',
          field: 'title',
        },
        {
          label: 'Document date',
          field: 'date_event',
        },
        {
          label: 'Next expiration',
          field: 'expiration',
        },

      ],

      vehicles_columns: [
        {
          label: 'Vehicle',
          field: 'name',
        },
        {
          label: 'Type of document',
          field: 'title',
        },
        {
          label: 'Document date',
          field: 'date_event',
        },
        {
          label: 'Next expiration',
          field: 'expiration',
        },

      ],

      equipments_columns: [
        {
          label: 'Equipment',
          field: 'name',
        },
        {
          label: 'Type of document',
          field: 'title',
        },
        {
          label: 'Document date',
          field: 'date_event',
        },
        {
          label: 'Next expiration',
          field: 'expiration',
        },

      ],

      plants_columns: [
        {
          label: 'Plant',
          field: 'name',
        },
        {
          label: 'Type of document',
          field: 'title',
        },
        {
          label: 'Document date',
          field: 'date_event',
        },
        {
          label: 'Next expiration',
          field: 'expiration',
        },

      ],

      fulfillments_columns: [
        {
          label: 'Fulfillment',
          field: 'title',
        },
        {
          label: 'Document date',
          field: 'date_event',
        },
        {
          label: 'Next expiration',
          field: 'expiration',
        },

      ],

      others_columns: [
        {
          label: 'Expiration',
          field: 'name',
        },
        {
          label: 'Date',
          field: 'expiration',
        },

      ],

      filters: [{description: i18n.t('Next 30 days'), id: "30days"}, {description: i18n.t('Next three months'), id: "3months"}, {description: i18n.t('This year'), id: "this_year"},],


      courses: [],
      medical: [],
      employee_documents: [],
      vehicles: [],
      equipments: [],
      plants: [],
      fulfillments: [],
      others: [],
      allow_export: false,
      company: null,
      searchTerm: '',
      end: moment().add(30, 'days').format('DD/MM/YYYY'),
      edit_department_id: 0,
      flatPickrConfig: {
        dateFormat: 'd/m/Y',
      },
    }
  },
  watch: {
    end: {
      handler: function (newValue) {
        console.log('watch end:' + newValue);
        if (newValue)
        this.refresh()
      },
      immediate: true,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    download_report() {
      let company_id = store.state.userCompany.id;

      const link = document.createElement("a");
      let end = this.end.replaceAll("/","-");
      if (end==="")
        end = "all"
      link.href = environment.baseURL + "/dashboard/downloadEventsInPeriod/" +company_id + "/" + end
      link.download = "report_scadenze.pdf";
      link.click();
      URL.revokeObjectURL(link.href);

    }

    , default_filter() {
      this.end = "";
      this.refresh()
    },
    setFilter(filter) {
      if(filter==="30days"){
        this.end = moment().add(30, 'days').format('DD/MM/YYYY')
      }
      else if(filter==="3months"){
        this.end = moment().add(3, 'months').format('DD/MM/YYYY')

      }
      else if(filter==="this_year"){
        this.end = moment().endOf('year').format('DD/MM/YYYY')

      }
    },
    getExpirationVariant(row) {

      console.log('getExpirationVariant row', row)

      if (row.color === 'red')
        return 'light-danger'
      else if (row.color === 'orange')
        return 'light-warning'
      else
        return 'light-success'

    },
    refresh() {
      console.log("refresh")
      this.$refs["courses_list"].showLoading = true
      this.$refs["medical_list"].showLoading = true
      this.$refs["documents_list"].showLoading = true
      this.$refs["vehicles_list"].showLoading = true
      this.$refs["plants_list"].showLoading = true
      this.$refs["equipments_list"].showLoading = true
      this.$refs["fulfillments_list"].showLoading = true
      this.$refs["others_list"].showLoading = true


      let company_id = store.state.userCompany.id;

      const dashboardService = new DashboardService(useJwt)
      let data = {
        start: this.start,
        end: this.end,
      }
      dashboardService.get_events_in_period(company_id, data).then(response => {
        console.log("data", response.data.events)

        this.courses = response.data.events.courses
        this.medical = response.data.events.medical
        this.employee_documents = response.data.events.employee_documents
        this.vehicles = response.data.events.vehicles
        this.equipments = response.data.events.equipments
        this.plants = response.data.events.plants
        this.fulfillments = response.data.events.fulfillments
        this.others = response.data.events.others
        this.company = response.data.events.company
        this.$refs["courses_list"].showLoading = false
        this.$refs["medical_list"].showLoading = false
        this.$refs["documents_list"].showLoading = false
        this.$refs["vehicles_list"].showLoading = false
        this.$refs["plants_list"].showLoading = false
        this.$refs["equipments_list"].showLoading = false
        this.$refs["fulfillments_list"].showLoading = false
        this.$refs["others_list"].showLoading = false

        // value allow_export true if at least one of courses, medical, employee_documents, vehicles, equipments, plants, fulfillments has at least one element
        this.allow_export = (this.courses.length > 0 || this.medical.length > 0 || this.employee_documents.length > 0 || this.vehicles.length > 0 || this.equipments.length > 0 || this.plants.length > 0 || this.fulfillments.length > 0)
      })

    },


  },
  mounted() {
    console.log('mounted')
    this.refresh()


    this.unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'selected_company') {
        console.log(`Updating selected_company to ${state.userCompany.id}`);
        this.refresh();
      }
    });


    //this.$http.get('/good-table/basic')
    //.then(res => { this.rows = res.data })
  },
  beforeDestroy() {
    this.unsubscribe();
  }
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
